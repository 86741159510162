// 学生的课消记录
<template>
  <div class="student_redcord">
    <!-- 视图部分 -->
    <Layout :showLoading="showLoading">
      <template v-slot:content>
        <div class="student_redcord_content">
          <!-- 查询条件部分 -->
          <div class="top_info">
            <span class="margin_right_10">上课日期 </span>

            <el-date-picker
              class="date_picker"
              v-model="selectDate"
              type="daterange"
              range-separator="至"
              size="small"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>

            <!-- 教室 -->
            <el-select class="select_item margin_left_12" v-model="classRoom" size="small">
              <el-option v-for="item in classRooms" :key="item.roodId" :label="item.roomName" :value="item.roomName"> </el-option>
            </el-select>

            <!-- 老师 -->
            <el-select class="select_item margin_left_12" v-model="teacher" size="small">
              <el-option v-for="item in teachers" :key="item.teacherId" :label="item.userName" :value="item.userName"> </el-option>
            </el-select>

            <!-- 班级 -->
            <el-select class="select_item margin_left_12" v-model="clazz" size="small">
              <el-option v-for="item in classList" :key="item.classId" :label="item.className" :value="item.className"> </el-option>
            </el-select>

            <!-- 输入查询的学生手机号或者姓名 -->
            <div class="margin_left_12 margin_right_20">
              <!-- 学生 -->
              学生：<el-input maxlength="15" class="input_item" size="small" clearable v-model="studentText" placeholder="请输入学生姓名或手机号"></el-input>
            </div>

            <!-- 查询,导出   class="commit com_bt margin_left_14" -->
            <el-button :loading="showExporLoading" v-if="tableData && tableData.length > 0" type="primary" size="medium" @click.stop="doExportAction">
              导&nbsp;&nbsp;出</el-button
            >
          </div>

          <!-- 学员表格部分 -->
          <div class="dv_table_view_content">
            <el-table
              ref="table_s_r"
              style="width: 100%; margin: auto"
              border
              stripe
              :data="tableData"
              :max-height="maxHeight"
              :header-cell-style="{ 'text-align': 'center' }"
              :cell-style="rowStyle"
            >
              <!-- 学生姓名 -->
              <el-table-column prop="studentName" label="学生姓名" width="120"></el-table-column>

              <!-- 学生id -->
              <el-table-column prop="studentId" label="学生id" width="90"></el-table-column>

              <!-- 手机号  -->
              <el-table-column prop="tel" :show-overflow-tooltip="true" label="手机号" width="130"></el-table-column>

              <!-- 剩余课时/总课时 -->
              <el-table-column prop="surplusClassHour" label="剩余课时/总课时" width="130"></el-table-column>

              <!-- 所在班级 -->
              <el-table-column prop="className" label="所在班级" width="120"></el-table-column>

              <!-- 班级老师 -->
              <el-table-column prop="teacherName" label="班级老师" width="120"></el-table-column>

              <!-- 上课教室 -->
              <el-table-column prop="roomName" label="上课教室" width="120"></el-table-column>

              <!-- 上课时间 -->
              <el-table-column prop="classTime" :show-overflow-tooltip="true" label="上课时间" width="260"></el-table-column>

              <!--  操作 -->
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <el-button :disabled="scope.row.pushButton == -1" @click="doStudent(scope.row)" type="text" size="small">查看</el-button>
                </template>
              </el-table-column>
            </el-table>
            <!-- 顶部的分页器部分 -->
            <div class="bom_page margin_bom_5" v-if="tableData && tableData.length > 0">
              <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currPage"
                :page-sizes="pageSizeArr"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalSize"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </template>
    </Layout>
  </div>
</template>
<script>
import { export_json_to_excel } from "../../../Excel/Export2Excel";
export default {
  data() {
    return {
      //学生搜索信息
      studentText: "",

      //筛选日期
      selectDate: "",

      //表格的最大高度
      maxHeight: 0,

      //总的数据条数
      totalSize: 0,

      //条数选择的数据
      pageSizeArr: [6, 8, 10],

      //每页显示的条数
      pageSize: 10,

      //当前显示的页码
      currPage: 1,

      //表格数据
      tableData: [],

      //教室
      classRooms: [],

      classRoom: "全部教室",

      //老师
      teachers: [],

      teacher: "全部老师",

      //班级
      classList: [],

      clazz: "全部班级",

      //开始时间
      startTime: "",

      //结束时间
      endTime: "",

      //教室Id 默认是0
      roomId: 0,

      //老师Id 默认是0
      teacherId: 0,

      //班级Id 默认是0
      classId: 0,

      //加载视图状态
      showLoading: -1,

      //导出的加载框
      showExporLoading: false,

      //导出表格的表头
      tHeaderArr: ["学生姓名", "学生id", "手机号", "剩余课时/总课时", "所在班级", "班级老师", "上课教室", "上课时间"],

      //导出表格的字段
      tBodyValue: ["studentName", "studentId", "tel", "surplusClassHour", "className", "teacherName", "roomName", "classTime"],
    };
  },
  created() {
    //初始化数据
    this.initData();

    //请求数据
    this.httpData(1);
  },
  methods: {
    //初始化表格数据
    initData() {
      let clientH = document.documentElement.clientHeight;
      this.maxHeight = clientH - 76 - -54 - 20 - 68 - 140;
    },

    //查询数据 curriculumType 1查询 3导出
    httpData(curriculumType) {
      let param = this.getHttpParams();
      param.type = 1;
      param.page = this.currPage;
      param.pagecount = this.pageSize;
      param.cancellationType = curriculumType;
      if (!this.isEmpty(this.startTime)) {
        param.startTime = this.startTime;
      }
      if (!this.isEmpty(this.endTime)) {
        param.endTime = this.endTime;
      }
      param.roomId = this.roomId;
      param.teacherId = this.teacherId;
      param.classId = this.classId;

      if (!this.isEmpty(this.studentText)) {
        param.word = this.studentText;
      }

      this.$http
        .fetchPost(this.$api.CLASSWAYRECORD, param)
        .then((res) => {
          //显示内容视图
          this.showLoading = 1;
          let result = res.data;

          //将表格滚动到顶部
          this.$nextTick(() => {
            this.$refs.table_s_r.bodyWrapper.scrollTop = 0;
          });

          if (result.state == 200) {
            if (curriculumType == 1) {
              //教室
              this.classRooms = result.data.roomList;

              //老师
              this.teachers = result.data.classPlanList;

              //班级
              this.classList = result.data.classInfoList;

              //获取列表数据
              this.totalSize = result.data.classCancellationPage.totalCount;

              //先清空一下表格数据
              this.tableData.splice(0, this.tableData.length);
            }
            // console.log("请求到的数据是:", result);
            //获取服务器的列表数据
            let serveData = result.data.classCancellationPage.classCancellationDataList;

            if (!this.isEmpty(serveData) && serveData.length > 0) {
              if (curriculumType == 1) {
                //查询
                this.tableData = serveData;
              } else {
                //导出
                //进行导出操作
                this.exportExcel(serveData);

                //请求下服务器
                this.httpMsg();
              }
            }
          } else {
            this.showErrorMsg(this.getMesage(res.data.state));
          }
        })
        .catch((err) => {
          this.showLoading = 1;
          console.log("请求数据异常");
        });
    },

    //点击导出的操作
    doExportAction() {
      this.showExporLoading = true;
      this.httpData(3);
    },

    //导出表格的方法
    exportExcel(exportArr) {
      require.ensure([], () => {
        //定义表头
        const tHeader = this.tHeaderArr;
        //定义表格数据的可想的属性key值
        const filterVal = this.tBodyValue;
        //把要导出的数据tableData存到list
        // const list = this.multipleSelection;
        const data = this.formatJson(filterVal, exportArr);
        //导出操作最后一个是表名字
        export_json_to_excel(tHeader, data, "学生课消记录");
        setTimeout(() => {
          this.showExporLoading = false;
        }, 2000);
      });
    },

    //格式化json的方法
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]));
    },

    //查看
    //查看学生--跳转到学员详情界面
    doStudent(item) {
      let param = this.getHttpParams();
      param.type = 17;
      param.student_id = item.studentId;

      this.$http
        .fetchPost(this.$api.StudentManageController, param)
        .then((res) => {
          //显示内容视图
          //   this.showLoading = 1;
          let resulte = res.data;
          if (resulte.state == 200) {
            this.$router.push({
              path: "/Home/StuRecord/StudentInfo",
              query: { userInfo: JSON.stringify(resulte.data) },
            });
          } else {
            this.showErrorMsg(this.getMesage(res.data.state));
          }
        })
        .catch((err) => {
          //   this.showLoading = 1;
          console.log("请求数据异常");
        });
    },

    //表格
    rowStyle() {
      return "text-align:center";
    },

    //每页显示的条数变化时触发
    handleSizeChange(val) {
      this.pageSize = val;
      // console.log("当前页显示的条数是:", this.pageSize);
      //切换页码
      this.currPage = 1;
      //重新刷新数据
      this.httpData(1);
    },

    //页面改变时触发
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.currPage = val;
      //重新刷新数据
      this.httpData(1);
    },
  },
  watch: {
    //选择时间的监听
    selectDate(newValue) {
      if (!this.isEmpty(newValue)) {
        this.startTime = this.selectDate[0];
        this.endTime = this.selectDate[1];
        // console.log("选择时间是:", this.start_date, this.end_date);
        //判断一下选择的时间间隔,最小1天,最大12个月
        if (!this.isRightTimeRange(this.startTime, this.endTime)) {
          this.showWarnMsg("选择的时间间隔必须大于1天");
          return;
        }

        //判断一下选择的时间间隔是否超过12个月
        if (this.isOverTimeRange(this.startTime, this.endTime)) {
          this.showWarnMsg("选择的时间间隔最大为12个月");
          return;
        }
      } else {
        this.startTime = "";
        this.endTime = "";
      }
      //还原页码重新请求数据
      this.currPage = 1;
      this.httpData(1);
    },

    //监听上课教室
    classRoom(newValue) {
      if (!this.isEmpty(newValue)) {
        let item = this.classRooms.find((item) => {
          return item.roomName == newValue;
        });
        this.roomId = item.roomId;
        //请求页面数据
        this.currPage = 1;
        this.httpData(1);
      }
    },

    //监听老师
    teacher(newValue) {
      if (!this.isEmpty(newValue)) {
        let item = this.teachers.find((item) => {
          return item.userName == newValue;
        });
        this.teacherId = item.teacherId;
        //请求页面数据
        this.currPage = 1;
        this.httpData(1);
      }
    },

    //监听班级
    clazz(newValue) {
      if (!this.isEmpty(newValue)) {
        let item = this.classList.find((item) => {
          return item.className == newValue;
        });
        this.classId = item.classId;
        //请求页面数据
        this.currPage = 1;
        this.httpData(1);
      }
    },

    //监听输入的搜索
    studentText(newValue) {
      //请求页面数据
      this.currPage = 1;
      this.httpData(1);
    },

    //导出的时候请求服务器
    httpMsg() {
      let param = this.getHttpParams();
      param.type = 16;
      this.$http.fetchPost(this.$api.LESSONMANAGER, param).then((res) => {
        //只用请求服务器,不需要做任何操作
      });
    },
  },
  computed: {},
  components: {},
};
</script>
<style lang="scss" scoped>
.student_redcord {
  min-width: $tab_min_width;
  height: 100%;
  .student_redcord_content {
    .top_info {
      display: flex;
      align-items: center;
    }
    .date_picker {
      width: 260px;
    }
    .select_item {
      width: 120px;
    }
    .input_item {
      width: 188px;
    }
    //表格内容部分
    .dv_table_view_content {
      margin: 10px 0;
      //底部的分页器部分
      .bom_page {
        position: absolute;
        bottom: 10px;
        width: 100%;
        left: 0;
        margin-top: 18px;
        display: flex;
        align-items: center;
        height: 40px;
        justify-content: center;
      }
    }

    ::v-deep .el-input__inner {
      height: 32px;
      line-height: 32px;
      padding-left: 6px;
    }

    ::v-deep .el-input__icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    ::v-deep .el-input__suffix {
      right: 0px;
    }
  }
}
</style>
