//课消记录
<template>
  <div class="course_away_record">
    <!-- 切换按钮 -->
    <div class="action">
      <div>
        <el-tabs v-model="radio" @tab-click="doAction">
          <el-tab-pane label="学 生 " name="1"></el-tab-pane>
          <span class="margin_left_40"></span>
          <el-tab-pane label="老 师 " name="2"></el-tab-pane>
        </el-tabs>
      </div>
    </div>

    <!-- 学生记录 -->
    <StudentRedcord v-if="radio == 1"></StudentRedcord>

    <!-- 老师记录 -->
    <TeacherRedcord v-if="radio == 2"></TeacherRedcord>
  </div>
</template>
<script>
import StudentRedcord from "@/views/educationalcenter/classawayrecord/StudentRedcord";
import TeacherRedcord from "@/views/educationalcenter/classawayrecord/TeacherRedcord";
export default {
  data() {
    return {
      radio: "1",
    };
  },
  created() {},
  methods: {
    doAction() {},
  },
  computed: {},
  components: { StudentRedcord, TeacherRedcord },
};
</script>
<style lang="scss" scoped>
.course_away_record {
  height: calc(100vh - 177px);
  margin-left: 14px;
  margin-right: 14px;
  .action {
    height: 54px;
    margin-top: 10px;
  }
  ::v-deep .el-tabs__active-bar {
    background-color: transparent !important;
    line-height: 10px;
  }
  ::v-deep.el-tabs__nav-wrap::after {
    position: static !important;
  }
}
</style>
