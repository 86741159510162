// 老师的课消记录
<template>
  <div class="teacher_redcord">
    <!-- 视图部分 -->
    <Layout :showLoading="showLoading">
      <template v-slot:content>
        <div class="teacher_redcord_content">
          <!-- 搜索条件部分 -->
          <div class="flex_h">
            <div class="lesson_date">
              <span class="margin_right_10">上课日期 </span>
              <el-date-picker
                class="date_picker"
                v-model="selectDate"
                type="daterange"
                range-separator="至"
                size="small"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </div>

            <div class="margin_left_40 margin_right_20">
              <!-- 老师 -->
              教师：<el-input class="input_item" size="small" clearable v-model="searchValue" placeholder="请输入教师姓名或手机号"></el-input>
            </div>

            <!-- 查询,导出  -->
            <el-button :loading="showExporLoading" v-if="tableData && tableData.length > 0" @click.stop="doExportAction" type="primary" size="medium">
              导&nbsp;&nbsp;出
            </el-button>
          </div>

          <!-- 表格部分 -->
          <div class="dv_table_view_content">
            <!-- stripe -->
            <el-table
              ref="table_t_r"
              style="width: 100%; margin: auto"
              border
              :data="tableData"
              :max-height="maxHeight"
              :header-cell-style="{ 'text-align': 'center' }"
              :cell-style="rowStyle"
            >
              <!-- 教师姓名 -->
              <el-table-column prop="teacherName" label="教师姓名" width="140"></el-table-column>

              <!-- 手机号  -->
              <el-table-column prop="tel" :show-overflow-tooltip="true" label="手机号" width="150"></el-table-column>

              <!-- 教师名下班级 -->
              <el-table-column label="教师名下班级" width="200">
                <template slot-scope="scope">
                  <div class="row_out">
                    <div class="row_item" v-for="(item, index) in scope.row.cancellation" :key="index">
                      {{ item.className }}
                    </div>
                  </div>
                </template>
              </el-table-column>

              <!-- 实际出勤/应出勤 -->
              <el-table-column label="实际出勤/应出勤" width="150">
                <template slot-scope="scope">
                  <div class="row_out">
                    <div class="row_item" v-for="(item, index) in scope.row.cancellation" :key="index">
                      {{ item.attendance }}
                    </div>
                  </div>
                </template>
              </el-table-column>

              <!-- 上课教室 -->
              <el-table-column label="上课教室" width="200">
                <template slot-scope="scope">
                  <div class="row_out">
                    <div class="row_item" v-for="(item, index) in scope.row.cancellation" :key="index">
                      {{ item.roomName }}
                    </div>
                  </div>
                </template>
              </el-table-column>

              <!-- 上课时间 -->
              <el-table-column label="上课时间" width="280">
                <template slot-scope="scope">
                  <div class="row_out">
                    <div class="row_item" v-for="(item, index) in scope.row.cancellation" :key="index">
                      {{ item.classTime }}
                    </div>
                  </div>
                </template>
              </el-table-column>

              <!--  操作 -->
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <div>
                    <el-button :disabled="scope.row.pushButton == -1" @click.stop="doLookTeacher(scope.row)" type="text" size="small">查看</el-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>

            <!-- 顶部的分页器部分 -->
            <div class="bom_page margin_bom_5" v-if="tableData && tableData.length > 0">
              <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currPage"
                :page-sizes="pageSizeArr"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalSize"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </template>
    </Layout>
  </div>
</template>
<script>
import { export_json_to_excel } from "../../../Excel/Export2Excel";

export default {
  data() {
    return {
      //加载框的值
      showLoading: -1,

      //输入的搜索值
      searchValue: "",

      //筛选日期
      selectDate: "",

      //表格的最大高度
      maxHeight: 0,

      //总的数据条数
      totalSize: 0,

      //条数选择的数据
      pageSizeArr: [6, 8, 10],

      //每页显示的条数
      pageSize: 10,

      //当前显示的页码
      currPage: 1,

      //表格数据
      tableData: [],

      //加载视图状态
      showLoading: -1,

      //导出的加载框
      showExporLoading: false,

      //开始时间
      startTime: "",

      //结束时间
      endTime: "",

      //导出表格的表头
      tHeaderArr: ["教师姓名", "手机号", "教师名下班级", "实际出勤/应出勤", "上课教室", "上课时间"],

      //导出表格的字段
      tBodyValue: ["teacherName", "tel", "className", "attendance", "roomName", "classTime"],
    };
  },
  created() {
    //初始化数据
    this.initData();

    //请求数据
    this.httpData(2);
  },
  methods: {
    //初始化表格数据
    initData() {
      let clientH = document.documentElement.clientHeight;
      this.maxHeight = clientH - 76 - -54 - 20 - 68 - 140;
    },

    //查询数据 curriculumType 2查询 4导出
    httpData(curriculumType) {
      let param = this.getHttpParams();
      param.type = 1;
      param.page = this.currPage;
      param.pagecount = this.pageSize;
      param.cancellationType = curriculumType;
      if (!this.isEmpty(this.startTime)) {
        param.startTime = this.startTime;
      }
      if (!this.isEmpty(this.endTime)) {
        param.endTime = this.endTime;
      }
      if (!this.isEmpty(this.searchValue)) {
        param.word = this.searchValue;
      }

      this.$http
        .fetchPost(this.$api.CLASSWAYRECORD, param)
        .then((res) => {
          //显示内容视图
          this.showLoading = 1;
          let result = res.data;

          //将表格滚动到顶部
          this.$nextTick(() => {
            this.$refs.table_t_r.bodyWrapper.scrollTop = 0;
          });

          if (result.state == 200) {
            if (curriculumType == 2) {
              //获取列表数据
              this.totalSize = result.data.teClassCancellationPage.totalCount;

              //先清空一下表格数据
              this.tableData.splice(0, this.tableData.length);
            }
            //获取服务器的列表数据
            let serveData = result.data.teClassCancellationPage.teClassCancellationData;

            if (!this.isEmpty(serveData) && serveData.length > 0) {
              if (curriculumType == 2) {
                //查询
                this.tableData = serveData;
              } else {
                //导出
                let exprotArr = [];

                //组装导出数据
                serveData.forEach((item) => {
                  let cancellation = item.cancellation;
                  if (!this.isEmpty(cancellation)) {
                    cancellation.forEach((obj) => {
                      let exporeObj = new Object();
                      exporeObj.teacherName = item.teacherName;
                      exporeObj.tel = item.tel;
                      exporeObj.className = obj.className;
                      exporeObj.attendance = obj.attendance;
                      exporeObj.roomName = obj.roomName;
                      exporeObj.classTime = obj.classTime;
                      exprotArr.push(exporeObj);
                    });
                  }
                });

                //进行导出操作
                this.exportExcel(exprotArr);

                //发送导出消息
                this.httpMsg();
              }
            }
          } else {
            this.showErrorMsg(this.getMesage(res.data.state));
          }
        })
        .catch((err) => {
          this.showLoading = 1;
          console.log("请求数据异常");
        });
    },

    //点击导出的操作
    doExportAction() {
      this.showExporLoading = true;
      this.httpData(4);
    },

    //导出表格的方法
    exportExcel(exportArr) {
      require.ensure([], () => {
        //定义表头
        const tHeader = this.tHeaderArr;
        //定义表格数据的可想的属性key值
        const filterVal = this.tBodyValue;
        //把要导出的数据tableData存到list
        // const list = this.multipleSelection;
        const data = this.formatJson(filterVal, exportArr);
        //导出操作最后一个是表名字
        export_json_to_excel(tHeader, data, "老师课消记录");
        setTimeout(() => {
          this.showExporLoading = false;
        }, 2000);
      });
    },

    //格式化json的方法
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]));
    },

    //查看老师--跳转到LookTeacher界面
    doLookTeacher(item) {
      this.$router.push({
        path: "/Home/LookTeacher",
        query: {
          tid: item.teacherId,
        },
      });
    },

    //表格
    rowStyle() {
      return "text-align:center";
    },

    //每页显示的条数变化时触发
    handleSizeChange(val) {
      this.pageSize = val;
      // console.log("当前页显示的条数是:", this.pageSize);
      //切换页码
      this.currPage = 1;
      //重新刷新数据
      this.httpData(2);
    },

    //页面改变时触发
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.currPage = val;
      //重新刷新数据
      this.httpData(2);
    },

    //导出的时候请求服务器
    httpMsg() {
      let param = this.getHttpParams();
      param.type = 16;
      this.$http.fetchPost(this.$api.LESSONMANAGER, param).then((res) => {
        //只用请求服务器,不需要做任何操作
      });
    },
  },
  computed: {},
  components: {},
  watch: {
    //选择时间的监听
    selectDate(newValue) {
      if (!this.isEmpty(newValue)) {
        this.startTime = this.selectDate[0];
        this.endTime = this.selectDate[1];
        //判断一下选择的时间间隔,最小1天,最大12个月
        if (!this.isRightTimeRange(this.startTime, this.endTime)) {
          this.showWarnMsg("选择的时间间隔必须大于1天");
          return;
        }

        //判断一下选择的时间间隔是否超过12个月
        if (this.isOverTimeRange(this.startTime, this.endTime)) {
          this.showWarnMsg("选择的时间间隔最大为12个月");
          return;
        }
      } else {
        this.startTime = "";
        this.endTime = "";
      }
      //还原页码重新请求数据
      this.currPage = 1;
      this.httpData(2);
    },

    //监听输入的搜索
    searchValue(newValue) {
      //请求页面数据
      this.currPage = 1;
      this.httpData(2);
    },
  },
};
</script>
<style lang="scss" scoped>
.teacher_redcord {
  height: 100%;
  min-width: $tab_min_width;
  .teacher_redcord_content {
    .lesson_date {
      display: flex;
      align-items: center;
    }

    .commit {
      height: 32px;
      width: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #409eff;
      color: white;
    }
    .temp_student {
      margin-bottom: 5px;
      float: right;
    }
    .date_picker {
      width: 260px;
    }
    .select_item {
      width: 120px;
    }
    .input_item {
      width: 188px;
    }
    //表格内容部分
    .dv_table_view_content {
      margin: 14px 0;

      .row_out {
        display: flex;
        flex-direction: column;
        .row_item {
          margin: 10px 0;
        }
      }
      //底部的分页器部分
      .bom_page {
        position: absolute;
        bottom: 0;
        width: 100%;
        left: 0;
        margin-top: 18px;
        display: flex;
        align-items: center;
        height: 40px;
        justify-content: center;
      }
    }
  }
}
</style>
